<template>
  <section class="hero">
    <div class="logo-animated-wrapper">
      <div class="logo-animated">
      <LogoAnimated />
      </div>
    </div>
    <div class="hero-nav" ref="heroNav">
      <h2><a href="#yapim">YAPIM</a></h2>
      <span></span>
      <h2><a href="#medya">MEDYA</a></h2>
    </div>
  </section>
</template>

<script>
import gsap from 'gsap';

import LogoAnimated from '@/components/LogoAnimated.vue';

export default {
  components: {
    LogoAnimated,
  },
  mounted() {
    const { heroNav } = this.$refs;
    gsap.set(heroNav, { opacity: 0, });
    gsap.to(heroNav, { opacity: 1, delay: 3.5, })
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
@import '../styles/variables.scss';

.hero {
  position:relative;
  height: 100vh;
  width: 100vw;
  background: url('../img/hero_bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 2fr;

.logo-animated-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 12vh;
}

.logo-animated {
  right: auto;
  left: auto;
  width: 80%;
  max-width: 80vw;
}

  .hero-nav {
    display: flex;
    justify-content: center;
    color: $text-light;
    margin-top: 3vw;

    h2 {
      font-size: 5vw;

      @media screen and (max-width: $xsmall) {
        font-size: 10vw;
      }
    }

    span {
      height: 6vw;
      width: 4px;
      background-color: $primary;
      margin-left: 3vw;
      margin-right: 3vw;

      @media screen and (max-width: $xsmall) {
        height: 12vw;
      }
    }
  }
}


</style>
