<template>
<section id="medya" ref="section">
    <div class="svg-wrapper"><svg id="left" ref="left" xmlns="http://www.w3.org/2000/svg" viewBox="0 46.2 28.8 26"><path id="parantez-sol" class="cls-1" d="M160.18,423.43H140.64a4.62,4.62,0,0,1-4.63-4.62V309.5a4.63,4.63,0,0,1,4.63-4.63h19.54a4.63,4.63,0,0,1,4.63,4.63h0a4.63,4.63,0,0,1-4.63,4.63h-7a4.62,4.62,0,0,0-4.63,4.62v90.42a4.62,4.62,0,0,0,4.63,4.62h7a4.63,4.63,0,0,1,4.63,4.63v.39A4.62,4.62,0,0,1,160.18,423.43Z" transform="translate(-136.01 -304.87)"/></svg></div>
    <div class="main">
      <img src="@/img/camera.png" alt="" ref="image">
      <div class="content-wrapper">
        <span class="line" ref="line"></span>
        <div class="content">
          <h2  class="title" ref="title"><span ref="titleInner">MEDYA</span></h2>
          <p ref="text">Prodüksiyonlar izlenebilir ve ulaşılabilir olmalıdır. Bütçeniz doğrultusunda hazırladığımız reklam içeriğinizi, hedeflerinize ulaşmak ve izleyiciden en etkili geri dönüşü sağlamak için kurgulayarak ilgili kanallardan gösterime koyuyoruz.</p>
        </div>
      </div>
    </div>
    <div class="svg-wrapper"><svg id="right" ref="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 46.2 28.8 26"><path id="parantez-sag" class="cls-1" d="M717.32,304.87h19.54a4.63,4.63,0,0,1,4.63,4.63V418.81a4.62,4.62,0,0,1-4.63,4.62H717.32a4.62,4.62,0,0,1-4.63-4.62h0a4.63,4.63,0,0,1,4.63-4.63h7a4.62,4.62,0,0,0,4.63-4.62V319.14a4.62,4.62,0,0,0-4.63-4.62h-7a4.63,4.63,0,0,1-4.63-4.63v-.39A4.63,4.63,0,0,1,717.32,304.87Z" transform="translate(-712.69 -304.87)"/></svg></div>
</section>
</template>


<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const { left, right, title, titleInner, line, text, image, section } = this.$refs;
    
    const animation = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "-100px",
        end: "+50%",
        markers: false,
        toggleActions: "play none none none",
      }
    });
 
    
    // Centering title
    const initialPosX = 450;
    const desiredPosX = (window.innerWidth / 2) * 0.95;
    const translateX = desiredPosX - initialPosX;

    const animate = () => {animation
    .set(image, { scale: 1, rotation: 135, x: "70vw", y: "35vh" })
    .set(left, { x: "45vw" })
    .set(right, { x: "-45vw" })
    .set(title, { opacity:0, scale: 2, y: "50%", x: translateX })
    .set([text, line], { opacity: 0 })
    .to([left, right], { duration: 1, x: 0, ease: "power2.inOut" },0)
    .to(title, { opacity: 1, ease: "power2.inOut" },0.4)
    .to(image, { duration: 1, scale:.7, rotation:0, x:0, y:0, ease:"power2.inOut" },1.2)
    .to(title, { duration: 1, scale:1, x:0, y:0, ease:"power2.inOut"},1.2)
    .to([text, line], { duration: 1, opacity: 1 }, 1.6)}

    if (window.innerWidth >= 768) {
      animate();
      }; 

  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

section {
  background-color: $bg-dark;
  height:100vh;
  width:100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .svg-wrapper {
    height:100%;
    display: flex;

    svg {
      position:relative;
      margin: 15px;
      width: 8vh;
      fill: $text-dark;  

      @media screen and (max-width: $small) {
        width: 6vh;
        margin: 0;
      }
    }
  }
  .main {
    display: flex;
    align-items:center;


    img {
      width: 20vw;
      margin: 5vw;
      transform: rotate(0deg);
    }

    .content-wrapper {
      display: flex;
      align-items: center;

      .line {
        position: relative;
        background-color: $primary;
        width: 2px;
        height: 150px;
        margin: 10px;
        margin-right: 5vw;
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 80%;
        
        h2 {
          font-size: 4rem;
          color: $text-dark;
          margin-bottom: 1vw;         
        }
      }
    }
        
    @media screen and (max-width: $small) {
      display: flex;
      flex-direction: column;
      img {
        position:absolute;
        transform: translateY(-15vh);
      }

      .content-wrapper {
        justify-content: center;

        .line {
          display: none;
        }

        .content {
          h2 {
            text-align: left;
            font-size: 2.5rem;
          }
          p {
            text-align: left;
            font-size: 0.9rem;
            line-height: 25px;
          }
        }
      }
    }
  }
}
</style>