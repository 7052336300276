<template>
  <header ref="header">
    <div class="logo-wrapper" ref="logo">
      <a href=#><Logo /></a>
    </div>
    <div class="nav-wrapper">
      <Hamburger />
    </div>
  </header>
</template>

<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Logo from '@/components/Logo.vue';
import Hamburger from '@/components/Hamburger.vue';

export default {

  components: {
    Logo,
    Hamburger,
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const { header, logo } = this.$refs;

    const anim = gsap.timeline({
        scrollTrigger: {
          trigger: yapim,
          start: "-100px",
          end: "+5000px",
          markers: false,
          toggleActions: "restart reverse restart reverse",
      }});
    
    anim
    .set(logo, {opacity: 0, visibility:"visible"})
    .to(logo, { duration: 0.5, opacity: 1, ease:"power4.in"})
    .to(header, { duration: 0.5, backgroundColor: "rgba(255,255,255,0.6)",ease:"power4.in"}, "-=0.5")
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
header {
  position: fixed;
  height: 100px;
  width: 100%;
  z-index: 10;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255,255,255,0);

  @media screen and (max-width:$xsmall) {
    height: 70px;
  }
  
  .logo-wrapper {
    position:relative;
    left: 10%;
    visibility: hidden;
  }
  }
</style>
