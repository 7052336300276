<template>
  <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <slot></slot>
    </div>
    <div class="flip-card-back">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12248.00787637961!2d32.6999601!3d39.8741938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe3511aab817c3680!2zUkggUE9axLBUxLBGIFJFS0xBTSBGxLBMTSBUQVNBUklN!5e0!3m2!1str!2str!4v1615989205500!5m2!1str!2str" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 30vw;
  height: 50vh;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  @media screen and (max-width: $small) {
    width: 70vw;
    height: 30vh;
  }
  @media screen and (max-width: $large) {
    height: 30vh; 
  }
  @media screen and (max-width: $xsmall) {
    width: 90vw; 
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  // @media screen and (max-width: $small) {
  //   transform: rotateY(180deg);
  // }
  
}

/* Do an horizontal flip when you move the mouse over the flip box container */
// .flip-card:hover .flip-card-inner {
//   // transform: rotateY(180deg);
// }

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  display:flex;
  align-items:center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */


/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  box-shadow: 0 10px 38px rgba(0,0,0,0.30), 0 10px 38px rgba(0,0,0,0.22);
  color: white;
  transform: rotateY(180deg);
}
</style>