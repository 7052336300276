<template>
  <Header />
    <Hero />
    <Yapim />
    <Medya />
    <Referanslar />
    <Iletisim />
    
</template>

<script>
import Header from '@/components/Header.vue';
import Hero from '@/views/Hero.vue';
import Yapim from '@/views/Yapim.vue';
import Medya from '@/views/Medya.vue';
import Referanslar from '@/views/Referanslar.vue';
import Iletisim from '@/views/Iletisim.vue';


export default {
  name: 'App',
  components: {
    Header,
    Hero,
    Yapim,
    Medya,
    Referanslar,
    Iletisim,
  },
};
</script>

<style lang="scss">
@import "@/styles/main";



</style>
