<template>
<div class="slider-wrapper">
<vueper-slides
  class="no-shadow"
  :visible-slides="4"
  slide-multiple
  :gap="3"
  :autoplay="true"
  :infinite="true"
  :slide-ratio="1 / 4"
  :dragging-distance="100"
  :breakpoints="{ 
    768: { visibleSlides: 3, slideMultiple: 3 },
    670: { visibleSlides: 2, slideMultiple: 2 },
    480: { visibleSlides: 2, bullets: false, slideRatio: 1/2,   }
     }">
  <vueper-slide v-for="(slide, i) in slides" :key="i">
    <template v-slot:content>
      <img class="logo" :src="slide.image" :alt="slide.title">
    </template>
  </vueper-slide>
  
</vueper-slides>


</div>

</template>

<script >
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  components: {
    VueperSlides, VueperSlide
  },
  data() {
    return {
      slides: [
        {
          title: 'FNSS',
          image: require('@/img/logo/FNSS.png')
        },
        {
          title: 'STM',
          image: require('@/img/logo/STM.png')
        },
        {
          title: 'Cbiko',
          image: require('@/img/logo/Cbiko.png')
        },
        {
          title: 'Nurol',
          image: require('@/img/logo/Nurol.png')
        },
        {
          title: 'Aselsan',
          image: require('@/img/logo/Aselsan.png')
        },
        {
          title: 'TAI',
          image: require('@/img/logo/TAI.png')
        },
        {
          title: 'Kültür Bakanlığı',
          image: require('@/img/logo/KulturBakanligi.png')
        },
        {
          title: '1915',
          image: require('@/img/logo/1915.png')
        },
        {
          title: 'SSB',
          image: require('@/img/logo/SSB.png')
        },
        {
          title: 'Havelsan',
          image: require('@/img/logo/Havelsan.png')
        },
        {
          title: 'ODTÜ GVO',
          image: require('@/img/logo/ODTUGVO.png')
        },
        {
          title: 'Tübitak',
          image: require('@/img/logo/Tubitak.png')
        },

      ]
    }
  }

}

</script>

<style lang="scss">
@import '../styles/variables';

.slider-wrapper {
  position: relative;
  width: 100%;
}

.vueperslides__track-inner {
  align-items:center;
}

.vuesuperslides__arrow svg {
  stroke-width: 5;
}

.vueperslide{
display: flex;
align-items:center;
justify-content: center;
}

.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(51, 51, 51, 0.5);
  box-shadow: none;
  transition: 0.3s;
  width: 14px;
  height: 14px;
}

.vueperslides__bullet--active .default {background-color: $text-dark;}

.vueperslides__bullet span {
  display: block;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}

.logo {
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
}

</style>