<template>
      <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 534.12 108.63"
    >
      <g id="brackets">
        <g id="bracket-left" ref="bracketLeft">
          <path
            class="cls-1"
            d="M178.44,340H161.15a4.09,4.09,0,0,1-4.09-4.09v-96.7a4.09,4.09,0,0,1,
            4.09-4.09h17.29a4.1,4.1,0,0,1,4.1,4.09h0a4.1,4.1,0,0,1-4.1,4.09h-6.22a4.1,
            4.1,0,0,0-4.09,4.1v80a4.09,4.09,0,0,0,4.09,4.09h6.22a4.1,4.1,0,0,
            1,4.1,4.09v.35A4.1,4.1,0,0,1,178.44,340Z"
            transform="translate(-157.06 -235.1)"
          />
        </g>
        <g id="bracket-right" ref="bracketRight">
          <path
            class="cls-1"
            d="M669.79,235.1h17.29a4.1,4.1,0,0,1,4.1,4.09v96.7a4.1,4.1,0,0,
            1-4.1,4.09H669.79a4.09,4.09,0,0,1-4.09-4.09h0a4.09,4.09,0,0,1,4.09-4.09H676a4.1,4.1,0,0,0,
            4.09-4.1v-80a4.09,4.09,0,0,0-4.09-4.09h-6.23a4.09,4.09,0,
            0,1-4.09-4.09v-.35A4.09,4.09,0,0,1,669.79,235.1Z"
            transform="translate(-157.06 -235.1)"
          />
        </g>
      </g>
      <g id="text" ref="text">
        <path
          id="A1"
          ref="A1"
          class="cls-3 text"
          d="M196.59,317.37H204a2.76,2.76,0,0,0,2.44-1.48l6.13-11.53h38.3L257,315.89a2.77,2.77,0,
          0,0,2.45,1.48h7.36a2.77,2.77,0,0,0,2.41-4.14l-30.47-54a2.77,
          2.77,0,0,0-2.41-1.41h-9.23a2.77,
          2.77,0,0,0-2.41,1.41l-30.47,54A2.78,2.78,0,0,0,196.59,
          317.37Zm21.82-23.76,13.2-24.12h0l0,0,.05-.08,0,.08,0,0h0l13.2,24.12H218.41Z"
          transform="translate(-157.06 -235.1)"
        />
        <path
          id="R"
          ref="R"
          class="cls-3 text"
          d="M279.23,314.59V260.64a2.77,2.77,0,0,1,2.77-2.77h48.11q13.45,0,13.46,11.54v14q0,
          11.53-13.46,11.54h-7.76l21.45,17.54a2.77,2.77,0,0,1-1.75,4.92H331a2.77,2.77,0,0,
          1-1.89-.75l-22.38-21a2.76,2.76,0,0,0-1.9-.75h-12v19.68a2.78,2.78,0,0,1-2.77,
          2.78H282A2.78,2.78,0,0,1,279.23,314.59ZM324,268.17H292.85v16.55H324a9.17,9.17,
          0,0,0,4.5-.79c1.16-.68,1.75-2,1.75-3.94v-7.15C330.23,269.73,328.14,268.17,324,268.17Z"
          transform="translate(-157.06 -235.1)"
        />
        <path
          id="A2"
          ref="A2"
          class="cls-3 text"
          d="M433.56,313.23l-30.47-54a2.77,2.77,0,0,0-2.41-1.41h-9.23a2.78,2.78,0,0,0-2.42,
          1.41l-30.46,54a2.77,2.77,0,0,0,2.41,4.14h7.36a2.77,2.77,0,0,0,2.45-1.48l6.12-11.53h38.3l6.12,
          11.53a2.78,2.78,0,0,0,2.45,1.48h7.36A2.78,2.78,0,0,0,433.56,313.23Zm-37.43-19.62H382.8L396,
          269.49h0l0,0,0-.08,0,.08,0,0h0l13.19,24.12Z"
          transform="translate(-157.06 -235.1)"
        />
        <path
          id="Z1"
          ref="Z1"
          class="cls-3 text"
          d="M500.56,306.16H462.08l40.52-41a2.76,2.76,0,0,0,.8-1.94v-2.57a2.77,
          2.77,0,0,0-2.77-2.77H443.08a2.77,2.77,0,0,0-2.77,2.77v5.66a2.77,2.77,
          0,0,0,2.77,2.77h38.64l-40.52,41a2.78,2.78,0,0,0-.79,1.95v2.56a2.78,
          2.78,0,0,0,2.77,2.78h57.38a2.78,2.78,0,0,0,2.77-2.78v-5.65A2.77,2.77,0,0,0,500.56,306.16Z"
          transform="translate(-157.06 -235.1)"
        />
        <path
          id="Z2"
          ref="Z2"
          class="cls-3 text"
          d="M651.58,306.16H613.1l40.52-41a2.76,2.76,0,0,0,.8-1.94v-2.57a2.77,
          2.77,0,0,0-2.77-2.77H594.1a2.77,2.77,0,0,0-2.77,2.77v5.66a2.77,2.77,
          0,0,0,2.77,2.77h38.64l-40.52,41a2.78,2.78,0,0,0-.79,1.95v2.56a2.78,
          2.78,0,0,0,2.77,2.78h57.38a2.78,2.78,0,0,0,2.77-2.78v-5.65A2.77,2.77,0,0,0,651.58,306.16Z"
          transform="translate(-157.06 -235.1)"
        />
        <g id="yapim-medya" class="text" ref="yapimMedya">
          <path
            class="cls-2"
            d="M523.17,329.93l-4.9,5.18v4.29h-2.22v-4.24l-4.92-5.23h2.94l3.25,3.64,3.26-3.64Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M535.82,339.4h-2.48l-1.14-2.07h-6l-1.1,
            2.07h-2.21l5.36-9.47h2.13Zm-4.54-3.78-2-3.85-2.11,3.85Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M549.1,333.88q0,1.83-2.13,
            1.83H541v3.69h-2.17v-9.47H547c1.42,0,2.13.61,2.13,
            1.84Zm-2.13-.55v-1a.66.66,0,0,0-.27-.63,1.53,
            1.53,0,0,0-.72-.12h-5v2.51h5a1.39,1.39,0,0,0,.72-.13A.64.64,0,0,0,547,333.33Z"
            transform="translate(-157.06 -235.1)"
          />
          <path class="cls-2" d="M554.7,339.4h-2.2v-9.47h2.2Z" transform="translate(-157.06 -235.1)" />
          <path
            class="cls-2"
            d="M571.9,339.4h-2v-5.89c0-.21,0-.53.07-1-.11.23-.29.56-.55,1l-3.56,
            4.73h-.44l-3.56-4.75a4.2,4.2,0,0,1-.57-1,9.25,9.25,
            0,0,1,.06.93v5.93h-1.78v-9.47h1.78l4.46,5.73,4.37-5.73h1.76Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M579.39,343.73h-1.34V325.38h1.34Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M598.07,339.4h-2v-5.89c0-.21,0-.53.08-1-.11.23-.3.56-.56,1L592,
            338.26h-.44L588,333.51a5.07,5.07,0,0,1-.57-1,9.25,9.25,0,0,1,
            .06.93v5.93H585.7v-9.47h1.78l4.46,5.73,4.37-5.73h1.76Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M612.49,339.4h-9.78v-9.47h9.69v1.62h-7.49v2.11h4.37v1.61h-4.37v2.35h7.58Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M628.08,334.61a4.44,4.44,0,0,1-2.1,4.06,7.72,7.72,0,0,1-3.89.73h-5.52v-9.47h6.09a6.11,
            6.11,0,0,1,3.86,1.1A4.26,4.26,0,0,1,628.08,
            334.61Zm-2.28,0q0-3-3.12-3h-3.91v6h2.85a6.32,6.32,0,0,0,
            2.93-.51A2.65,2.65,0,0,0,625.8,334.58Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M641.53,329.93l-4.9,5.18v4.29h-2.22v-4.24l-4.92-5.23h2.94l3.24,3.64,3.27-3.64Z"
            transform="translate(-157.06 -235.1)"
          />
          <path
            class="cls-2"
            d="M654.17,339.4H651.7l-1.14-2.07h-6l-1.1,
            2.07h-2.21l5.36-9.47h2.13Zm-4.53-3.78-2-3.85-2.11,3.85Z"
            transform="translate(-157.06 -235.1)"
          />
        </g>
      </g>
      <g id="square" ref="square">
        <path
          id="outer"
          class="cls-2"
          d="M511.66,271.79q0-8.28,3.27-11.26,3.09-2.82,11.48-2.81H570.2q8.38,0,11.48,2.81,3.27,
          3,3.27,11.26v31.35q0,8.28-3.27,11.26-3,2.81-11.48,2.81H526.41q-8.45,
          0-11.48-2.81-3.27-3-3.27-11.26ZM528.28,306h40.11a2.77,2.77,0,0,0,2.77-2.77V271.12a2.77,
          2.77,0,0,0-2.77-2.77H528.28a2.77,2.77,0,0,0-2.77,2.77v32.12A2.77,2.77,0,0,0,528.28,306Z"
          transform="translate(-157.06 -235.1)"
        />
        <path
          id="inner"
          class="cls-1"
          d="M548.3,277.17a10.3,10.3,0,1,0,10.3,10.29A10.29,
          10.29,0,0,0,548.3,277.17Zm0,12.82a2.81,2.81,0,1,1,
          2.81-2.81A2.81,2.81,0,0,1,548.33,290Z"
          transform="translate(-157.06 -235.1)"
        />
      </g>
    </svg>
</template>

<script>
import gsap from 'gsap';

export default {
  mounted() {
    const { square, bracketLeft, bracketRight, A1, R, A2, Z1, Z2, yapimMedya } = this.$refs;
    const textArray = [A1, R, A2, Z1, Z2, yapimMedya];
    

    const mainAnim = gsap.timeline({
      repeat: 0,
      repeatDelay:2,
    });

    gsap.set(square, {
      x: -125,
    })

    gsap.set([square, ...textArray], {
      visibility: "visible",
      opacity: 0,
    });

    gsap.set(bracketLeft, {
      x: 225,
    });

    gsap.set(bracketRight, {
      x: -225,
    })


    gsap.to(bracketLeft, {
      duration:2,
      ease: "power2.out",
      x: 0,
      delay: 0.5,
    });

    gsap.to(bracketRight, {
      duration: 2,
      ease: "power2.out",
      x: 0,
      delay: 0.5,
    });

    mainAnim
      .to(square, {
        duration: 0.5,
        opacity: 1,
        delay: 0.5,
      })
      .to(square, {
        duration:1,
        rotation: 180,
        transformOrigin: "center",
        ease: "power1.out",
      })
      .to(square, {
        duration: 1,
        x: 0.7, 
        ease: "Back.in(1.7)",
      }, "-=0.3")
      .staggerFromTo([textArray], 0.5, 
      { ease: "Back.easeOut.config(1)", opacity: 0, y: "+=10"},
      { ease: "Back.easeOut.config(1)", opacity: 1, y: "-=10"}, 0.1
      );
  }
}
</script>

<style scoped>

.cls-1 {
  fill: #333;
}
.cls-2 {
  fill: #96487f;
}
.cls-3 {
  fill: #e5e5e5;
}


</style>
