<template>
<section id="referanslar" ref="section">
    <div class="svg-wrapper"><svg id="left" ref="left" xmlns="http://www.w3.org/2000/svg" viewBox="0 46.2 28.8 26"><path id="parantez-sol" class="cls-1" d="M160.18,423.43H140.64a4.62,4.62,0,0,1-4.63-4.62V309.5a4.63,4.63,0,0,1,4.63-4.63h19.54a4.63,4.63,0,0,1,4.63,4.63h0a4.63,4.63,0,0,1-4.63,4.63h-7a4.62,4.62,0,0,0-4.63,4.62v90.42a4.62,4.62,0,0,0,4.63,4.62h7a4.63,4.63,0,0,1,4.63,4.63v.39A4.62,4.62,0,0,1,160.18,423.43Z" transform="translate(-136.01 -304.87)"/></svg></div>
    <div class="main">
      <img src="@/img/ajanda.png" alt="" ref="image">
      <div class="content-wrapper">
        <span class="line" ref="line"></span>
        <div class="content">
          <h2  class="title" ref="title">REFERANSLAR</h2>
          <span ref="text"><RefCarousel /></span>
        </div>
      </div>
    </div>
    <div class="svg-wrapper"><svg id="right" ref="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 46.2 28.8 26"><path id="parantez-sag" class="cls-1" d="M717.32,304.87h19.54a4.63,4.63,0,0,1,4.63,4.63V418.81a4.62,4.62,0,0,1-4.63,4.62H717.32a4.62,4.62,0,0,1-4.63-4.62h0a4.63,4.63,0,0,1,4.63-4.63h7a4.62,4.62,0,0,0,4.63-4.62V319.14a4.62,4.62,0,0,0-4.63-4.62h-7a4.63,4.63,0,0,1-4.63-4.63v-.39A4.63,4.63,0,0,1,717.32,304.87Z" transform="translate(-712.69 -304.87)"/></svg></div>
</section>
</template>


<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import RefCarousel from '@/components/RefCarousel.vue';

export default {
  components: {
    RefCarousel
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const { left, right, title, line, text, image, section } = this.$refs;
    
    const animation = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "-100px",
        end: "+50%",
        markers: false,
        toggleActions: "play none none none",
      }
    });

    let titleScale = 2;
    let val;
    if (window.innerWidth > 1400) { val = 1130 } else if (window.innerWidth > 1000 && window.innerWidth <= 1400) { val = 750 } else if (window.innerWidth <= 1000 ) { val = 600, titleScale=1.5 }
    

    const posX = (window.innerWidth / 2) - val;
    const animate = () => {animation
    .set(image, { scale: 2, rotation: 0, x: "55vw", y: "40vh" })
    .set(left, { x: "45vw" })
    .set(right, { x: "-45vw" })
    .set(title, { opacity:0, scale: titleScale, y: "120%", x: posX })
    .set([text, line], { opacity: 0 })
    .to([left, right], { duration: 1, x: 0, ease: "power2.inOut" },0)
    .to(title, { opacity: 1, ease: "power2.inOut" },0.4)
    .to(image, { duration: 1, scale:1, rotation:17, x:0, y:0, ease:"power2.inOut" },1.2)
    .to(title, { duration: 1, scale:1, x:0, y:0, ease:"power2.inOut"},1.2)
    .to([text, line], { duration: 1, opacity: 1 }, 1.6)}

    if (window.innerWidth >= 768) {
      animate();
      }; 

  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

section {
  background-color: $bg-light;
  height:100vh;
  width:100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .svg-wrapper {
    height:100%;
    display: flex;

    svg {
      position:relative;
      margin: 15px;
      width: 8vh;
      fill: $text-dark;  

      @media screen and (max-width: $small) {
        width: 6vh;
        margin: 0;
      }
    }
  }
  .main {
    display: flex;
    align-items:center;
    width: 80%;
    
    img {
      width: 15vw;
      margin: 5vw;
      transform: rotate(25deg);
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .line {
        position: relative;
        background-color: $primary;
        width: 2px;
        height: 150px;
        margin: 10px;
        margin-right: 5vw;
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 60%;
        @media screen and (max-width: $small) {
          width: 90%;
        }
        
        h2 {
          font-size: 4rem;
          color: $text-dark;
          margin-bottom: 1vw;       
          text-align: center;  
          @media screen and (max-width: $small) {
            font-size: 2rem;
          }

        }
      }
    }
        @media screen and (max-width: $small) {
      display: flex;
      flex-direction: column;

      img {
        position: absolute;
        transform: translateY(-15vh) rotate(17deg);
      }

      .content-wrapper {
        justify-content: center;

        .line {
          display: none;
        }

        .content {
          text-align: center;
          .title {
            margin-bottom: 0;
          }
        }
      }
    }

  }
}
</style>