<template>

<div class="hamburger-menu">
    <input id="menu__toggle" type="checkbox" />
    <label class="menu__btn" for="menu__toggle">
      <span></span>
    </label>

    <ul class="menu__box">
      <li><a class="menu__item" href="#">Anasayfa</a></li>
      <li><a class="menu__item" href="#yapim">Yapım</a></li>
      <li><a class="menu__item" href="#medya">Medya</a></li>
      <li><a class="menu__item" href="#referanslar">Referanslar</a></li>
      <li><a class="menu__item" href="#iletisim">İletişim</a></li>
    </ul>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

#menu__toggle {
  opacity: 0;
  display: none;
}
#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  right: 0;
}
.menu__btn {
  display: flex;
  align-items: center;
  position: relative;
  right: 90px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
  @media screen and (max-width: $xsmall) {
    right: 40px;
  }
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: $primary;
  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  right: -100%;
  width: 200px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #ECEFF1;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, .2);
  transition-duration: .5s;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .5s;
}
.menu__item:hover {
  background-color: #CFD8DC;
}
</style>
