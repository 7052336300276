<template>
  <img src="../img/logo.svg">
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

img {
  width: 100%;

  @media screen and (max-width: $xsmall ){
    width: 70%;
  }
}
</style>
